<template>
  <div>
    <div class="alert-body text-center">
      <strong>{{ errorMessage }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyVehicleErrorDialog',
  props: {
    errorMessage: String
  }
}
</script>