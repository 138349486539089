<template>
  <b-modal
      id="modal-edit-vehicle"
      title="Edit Vehicle"
      ok-only
      ok-title="Save Changes"
      size="xl"
      ref="modal-edit-vehicle"
      no-close-on-backdrop
    >
      <!-- VEHICLE DESCRIPTION -->
      <b-row>
        <b-col cols="12">
          <h2 class="media-heading mb-4 text-center">
            <!-- <strong>{{ vehicle.description }}</strong> -->
          </h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6">
        <!-- VEHICLE IMAGE & EDIT BUTTON -->
          <b-row>
            <b-col cols="12">
              <div class="card position-relative mx-auto w-50">
                <div class="position-absolute mt-1 ml-1">
                  <input
                    @change="uploadImage"
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    accept="image/*"
                  />
                  <b-button
                    @click="onImagePick"
                    variant="danger"
                    class="btn-icon rounded-circle"
                  ><feather-icon icon="Edit2Icon" /></b-button>
                </div>
                <!-- <b-img
                  :src="vehicle.image"
                  blank-color="#ccc"
                  alt="placeholder"
                  thumbnail
                  class="w-100"
                ></b-img> -->
              </div>
            </b-col>
            <b-col cols="12" class="text-center">
              <h2>
                <!-- <strong>{{ vehicle.plate }}</strong> -->
              </h2>
            </b-col>
          </b-row>
        </b-col>

        <!-- VEHICLE DETAILS -->
        <b-col cols="12" lg="6">
          <b-row>
            <b-col cols="12">
              <b-card-text class="mb-0">
              <validation-observer ref="editVehicle">
                <b-form-group label="Model Of Vehicle">
                  <validation-provider
                    #default="{ errors }"
                    name="Model Of Vehicle"
                    rules="required"
                  >
                    <!-- <b-form-input
                      v-model="vehicle.model"
                      placeholder="Model"
                    /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Vehicle Year">
                  <validation-provider
                    #default="{ errors }"
                    name="Vehicle Year"
                    rules="required"
                  >
                    <!-- <b-form-input
                      v-model="vehicle.plate"
                      placeholder="Model"
                    /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Color of Vehicle">
                  <validation-provider
                    #default="{ errors }"
                    name="vehicle_color"
                    rules="required"
                  >
                    <!-- <b-form-input
                      v-model="vehicle.model"
                      placeholder="Color of Vehicle"
                    /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-observer>
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
</template>

<script>
import { 
  ValidationProvider, ValidationObserver
} from 'vee-validate'

export default {
  name: 'EditVehicleModal',
  props: {
    parentVehicle: [Array, Object]
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      vehicle: this.parentVehicle
    }
  },
  methods: {
    saveChanges () {
      //
    },

    onImagePick () {
      //
    },

    uploadImage () {
      //
    }
  }
}
</script>