<template>
  <div>
    <b-overlay :show="isLoading">
      <div v-if="!isLoading">
        <b-button
          variant="primary"
          class="mb-2"
          @click="$router.go(-1)"
        >
          <FeatherIcon icon="ArrowLeftIcon" />
          {{ $t('go_back') }}
        </b-button>
        <b-card>
          <DeliveryProgress :order="order" />
        </b-card>
        <b-card>
          <b-row>
            <b-col
              cols="12"
              lg="6 border-right"
            >
              <b-row>
                <b-col cols="12">
                  <DeliveryOrigin :order="order" />
                </b-col>
                <b-col cols="12">
                  <DeliveryDestination :order="order" />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <OrderLogs :order="order" />
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-row>
            <b-col cols="12">
              <ProductDetails :order="order" />
            </b-col>
          </b-row>
        </b-card>

        <b-row>
          <b-col>
            <ProofDelivery :order="order" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  ProofDelivery, DeliveryOrigin, OrderLogs, DeliveryDestination, DeliveryProgress, ProductDetails,
} from '@/components'
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryTracking',
  components: {
    ProofDelivery,
    DeliveryOrigin,
    OrderLogs,
    DeliveryDestination,
    DeliveryProgress,
    ProductDetails,
  },
  data() {
    return {
      currentIndex: 0,
    }
  },
  computed: {
    ...mapGetters({
      order: 'orders/orderDetails',
      isLoading: 'orders/checkLoadingState',
    }),
  },
  created() {
    this.getOrder()
  },
  methods: {
    getOrder() {
      this.$store.dispatch('orders/fetchOrder', { id: this.$route.params.orderId })
    },
  },
}
</script>
