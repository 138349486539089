// My Vehicle
import AddVehicleButton from "./MyVehicle/AddVehicleButton.vue";
import ErrorDialog from "./MyVehicle/ErrorDialog.vue";
import VehicleDataCarousel from "./MyVehicle/VehicleDataCarousel.vue";
import AddVehicleModal from "./MyVehicle/AddVehicleModal.vue";
import EditVehicleModal from "./MyVehicle/EditVehicleModal.vue";

// MyDashboard
import BaseWelcome from "./MyDashboard/BaseWelcome.vue";
import BasePerformance from "./MyDashboard/BasePerformance.vue";
import BaseTask from "./MyDashboard/BaseTask.vue";
import BaseMilestone from "./MyDashboard/BaseMilestone.vue";
import BaseGuideline from "./MyDashboard/BaseGuideline.vue";

// DeliveryTracking
import OrderLogs from "./DeliveryTracking/OrderLogs.vue";
import DeliveryOrigin from "./DeliveryTracking/DeliveryOrigin.vue";
import DeliveryDestination from "./DeliveryTracking/DeliveryDestination.vue";
import ProofDelivery from "./DeliveryTracking/ProofDelivery.vue";
import DeliveryProgress from "./DeliveryTracking/DeliveryProgress.vue";

// Order Summary
import ProductDetails from "./OrderSummary/ProductDetails.vue";
import SmartTruck from "./OrderSummary/SmartTruck.vue";
import OrderDetails from "./OrderSummary/OrderDetails.vue";

// HyperMarket
import HyperMarketOrders from "./HyperMarket/HyperMarketOrders.vue";
import PurchaseOrderDetails from "./ViewPo/PurchaseOrderDetails.vue";

// Common Components
import MyTable from "./MyTable.vue";

// Fleet Forwarding
import OrderFilter from "./FleetForwarding/OrderFilter.vue";
import DeliverySummary from "./FleetForwarding/DeliverySummary.vue";

// Chartered Truck
import AddressDetails from "./CharteredTruck/AddressDetails.vue";
import DeliveryDetails from "./CharteredTruck/DeliveryDetails.vue";
import DeliveryType from "./CharteredTruck/DeliveryType.vue";
import CharteredDeliverySummary from "./CharteredTruck/DeliverySummary.vue";

export {
  // My Vehicle
  AddVehicleButton,
  ErrorDialog,
  VehicleDataCarousel,
  AddVehicleModal,
  EditVehicleModal,
  // My Dashboard
  BaseWelcome,
  BasePerformance,
  BaseTask,
  BaseMilestone,
  BaseGuideline,
  // Delivery Tracking
  OrderDetails,
  OrderLogs,
  ProductDetails,
  DeliveryOrigin,
  DeliveryDestination,
  SmartTruck,
  ProofDelivery,
  DeliveryProgress,
  // HyperMarket
  HyperMarketOrders,
  PurchaseOrderDetails,
  // Common Components
  MyTable,
  // Fleet Forwarding
  OrderFilter,
  DeliverySummary,
  // Chartered Truck
  DeliveryType, 
  AddressDetails, 
  DeliveryDetails, 
  CharteredDeliverySummary,
  
};
