<template>
  <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header>
      <b-card-title class="text-uppercase">
        <h1>
          <strong>
            <FeatherIcon
              icon="PackageIcon"
              size="30"
            />
            {{ $t('product_details') }}
          </strong>
        </h1>
      </b-card-title>
    </b-card-header>

    <!-- BODY -->
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-list-group flush>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('quantity') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.quantity }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('size') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.size }} {{ order.unit }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('remarks') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.remarks ? order.remarks : 'No remarks' }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('category') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.category.text }}</span>
              </h5>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-list-group flush>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('packaging') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.packaging.text }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('SKU') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.sku }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('temperature') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.temperature }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('weight') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.weight }} kg</span>
              </h5>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="12">
          <b-list-group>
            <b-list-group-item class="border-0 py-0">
              <b-row>
                <b-col cols="12">
                  <h5>
                    <strong>{{ $t('documents') }}:</strong>
                  </h5>
                  <b-button
                    size="md"
                    variant="danger"
                    class="mb-1 mr-1"
                    @click="reprint('do', order.id)"
                  >
                    <strong>{{ $t('reprint') }} DO</strong>
                  </b-button>
                  <b-button
                    size="md"
                    variant="danger"
                    class="mb-1 mr-1"
                    @click="reprint('cover', order.id)"
                  >
                    <strong>{{ $t('reprint') }} Covernote</strong>
                  </b-button>
                  <b-button
                    size="md"
                    variant="danger"
                    class="mb-1"
                    @click="reprint('grn', order.id)"
                  >
                    <strong>{{ $t('reprint') }} GRN</strong>
                  </b-button>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'ProductDetails',
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
  methods: {
    async reprint(type, id) {
      let fileName = ''
      if (type === 'do') {
        fileName = 'delivery-order'
      }
      if (type === 'cover') {
        fileName = 'cover-note'
      }
      if (type === 'grn') {
        fileName = 'good-return-note'
      }
      const response = await this.$http.get(`/b2b/${type}/id/${id}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `${fileName}-${id}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
  },
}
</script>

<style>

</style>
