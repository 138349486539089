<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <b-carousel
        id="carousel-1"
        v-model="currentIndex"
        :interval="0"
        controls
        indicators
        background="#ababab"
      >
        <b-carousel-slide
          v-for="vehicle in parentVehicleData"
          :key="vehicle.id"
          style="color: #fff"
        >
          <template #img>
            <div class="clearfix">
              <b-img
                :src="`${$s3URL}uploads/user/${vehicle.image}`"
                center
                fluid
                thumbnail
                width="420"
                :alt="$t('vehicle_image')"
                class="mb-1 mb-sm-0"
              />
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-col>
    <!-- End 2 Carousel Vehicle-->
    <!--Row 3 -->
    <b-col
      cols="12"
      class="mb-4"
    >
      <b-row class="mb-1">
        <!-- PLATE NUMBER -->
        <b-col
          cols="12"
          lg="4"
        >
          <label for="plate_number"><strong>{{ $t('plate_number') }}</strong></label>
          <b-form-input
            id="plate_number"
            v-model="vehicles[currentIndex].plate"
            readonly
            class="text-uppercase"
          />
        </b-col>

        <!-- REGION OF VEHICLE -->
        <b-col
          cols="12"
          lg="4"
        >
          <label for="vehicle_region"><strong>{{ $t('vehicle_region') }}</strong></label>
          <b-form-input
            id="vehicle_region"
            v-model="vehicles[currentIndex].region"
            :readonly="isEdit ? false : true"
            class="text-uppercase"
          />
        </b-col>

        <!-- TYPE OF VEHICLE -->
        <b-col
          cols="12"
          lg="4"
        >
          <label for="vehicle_type"><strong>{{ $t('vehicle_type') }}</strong></label>
          <b-form-input
            id="vehicle_type"
            v-model="vehicles[currentIndex].type"
            :readonly="isEdit ? false : true"
          />
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <!-- COLOR OF VEHICLE -->
        <b-col
          cols="12"
          lg="4"
        >
          <label for="vehicle_color"><strong>{{ $t('vehicle_color') }}</strong></label>
          <b-form-input
            id="vehicle_color"
            v-model="vehicles[currentIndex].color"
            :readonly="isEdit ? false : true"
          />
        </b-col>

        <!-- MODEL OF VEHICLE -->
        <b-col
          cols="12"
          lg="4"
        >
          <label for="vehicle_model"><strong>{{ $t('vehicle_model') }}</strong></label>
          <b-form-input
            id="vehicle_model"
            v-model="vehicles[currentIndex].model"
            :readonly="isEdit ? false : true"
          />
        </b-col>

        <!-- MAKE OF VEHICLE -->
        <b-col
          cols="12"
          lg="4"
        >
          <label for="vehicle_make"><strong>{{ $t('vehicle_make') }}</strong></label>
          <b-form-input
            id="vehicle_make"
            v-model="vehicles[currentIndex].make"
            :readonly="isEdit ? false : true"
          />
        </b-col>

      </b-row>

      <b-row class="mt-3">
        <b-col cols="6">
          <b-button
            v-if="!isEdit"
            variant="dark"
            block
            @click="handleEditVehicle"
          >
            <FeatherIcon icon="EditIcon" /> {{ $t('edit') }}
          </b-button>
          <b-button
            v-else
            variant="success"
            block
            @click="handleSaveUpdatedVehicle(vehicles[currentIndex].id)"
          >
            <b-icon-hand-thumbs-up /> {{ $t('save') }}
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            variant="danger"
            block
            @click="deleteVehicle(vehicles[currentIndex].id)"
          >
            <FeatherIcon icon="TrashIcon" /> {{ $t('remove') }}
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BIconHandThumbsUp,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'VehicleDataCarousel',
  components: {
    BIconHandThumbsUp,
  },
  props: {
    parentIndex: {
      required: true,
      type: Number,
    },
    parentVehicleData: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      currentIndex: this.parentIndex,
      vehicles: this.parentVehicleData,
      isEdit: false,
    }
  },
  watch: {
    parentVehicleData(val) {
      this.vehicles = val
    },
  },
  methods: {
    handleEditVehicle() {
      this.isEdit = true
    },
    handleSaveUpdatedVehicle(vehicleId) {
      const formData = {
        _method: 'PATCH',
        region: this.vehicles[this.currentIndex].region,
        type: this.vehicles[this.currentIndex].type,
        make: this.vehicles[this.currentIndex].make,
        model: this.vehicles[this.currentIndex].model,
        color: this.vehicles[this.currentIndex].color,
      }
      this.$http.post(`/vehicles/${vehicleId}`, formData).then(response => {
        if (response.status === 200) {
          this.$emit('get-vehicles')
          this.isEdit = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('vehicle_updated_successfully'),
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    deleteVehicle(vId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Do you want to remove this vehicle?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`/vehicles/${vId}`, { _method: 'DELETE' }).then(response => {
            if (response.status === 200) {
              this.$emit('get-vehicles')
              this.$swal({
                icon: 'success',
                title: 'Removed!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      })
    },
  },
}
</script>
