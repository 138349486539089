<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-card-group>
            <b-col
              cols="12"
              lg="3"
            >
              <b-list-group>
                <b-list-group-item class="border-0 text-center">
                  <h6 class="font-weight-bold">
                    {{ $t('order_id' ) }}
                  </h6>
                  <span>{{ details.reference_id }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              cols="12"
              lg="3"
            >
              <b-list-group>
                <b-list-group-item class="border-0 text-center">
                  <h6 class="font-weight-bold">
                    {{ $t('purchase_order_number') }}
                  </h6>
                  <span>{{ details.po_number }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              cols="12"
              lg="3"
            >
              <b-list-group>
                <b-list-group-item class="border-0 text-center">
                  <h6 class="font-weight-bold">
                    {{ $t('PO_expiry_date') }}
                  </h6>
                  <span>{{ $helpers.formatDate(details.po_expiry_date) }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              cols="12"
              lg="3"
            >
              <b-list-group>
                <b-list-group-item class="border-0 text-center">
                  <h6 class="font-weight-bold">
                    {{ $t('invoice_number') }}
                  </h6>
                  <span>{{ details.invoice_number }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-card-group>
        </b-card>
      </b-col>
    </b-row>

    <!-- ORDER DETAILS -->

    <!-- SENDER & RECIPIENT DETAILS -->
    <b-row class="mb-2">
      <b-col
        cols="12"
        lg="6"
      >
        <b-card class="d-flex flex-column h-100 p-0">
          <b-card-group>
            <b-card-body class="p-0">
              <b-card-text>
                <h4 class="font-weight-bold">
                  {{ $t('sender_details') }}
                </h4>
              </b-card-text>

              <b-media class="mb-2">
                <template #aside>
                  <b-img
                    :src="details.user.pic_url"
                    thumbnail
                    width="150px"
                  />
                </template>

                <h3 class="font-weight-bold">
                  {{ details.user.name }}
                </h3>
                <p>{{ details.user.default_address.area }}, {{ details.user.default_address.state }}</p>
              </b-media>

              <b-card-text>
                <b-list-group>
                  <b-list-group-item class="border-0">
                    <h6>{{ $t('sender_address') }}</h6>
                    <p>{{ details.sender.complete }}</p>
                  </b-list-group-item>
                  <b-list-group-item class="border-0">
                    <h6>{{ $t('person_in_charge') }}</h6>
                    <p>{{ details.sender.name }}</p>
                  </b-list-group-item>
                  <b-list-group-item class="border-0">
                    <h6>{{ $t('mobile_number') }}</h6>
                    <p>{{ details.sender.phone }}</p>
                  </b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-card-body>
          </b-card-group>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card class="d-flex flex-column h-100 p-0">
          <b-card-group>
            <b-card-body class="p-0">
              <b-card-text>
                <h4 class="font-weight-bold">
                  {{ $t('recipient_details') }}
                </h4>
              </b-card-text>

              <b-media class="mb-2">
                <template #aside>
                  <b-img
                    :src="details.client.logo"
                    thumbnail
                    width="150px"
                  />
                </template>

                <h3 class="font-weight-bold">
                  {{ details.client.name }}
                </h3>
                <p>{{ details.recipient.area }}, {{ details.recipient.state }}</p>
              </b-media>

              <b-card-text>
                <b-list-group>
                  <b-list-group-item class="border-0">
                    <h6>{{ $t('recipient_address') }}</h6>
                    <p>{{ details.recipient.complete }}</p>
                  </b-list-group-item>
                  <b-list-group-item class="border-0">
                    <h6>{{ $t('person_in_charge') }}</h6>
                    <p>{{ details.recipient.name }}</p>
                  </b-list-group-item>
                  <b-list-group-item class="border-0">
                    <h6>{{ $t('mobile_number') }}</h6>
                    <p>{{ details.recipient.phone }}</p>
                  </b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-card-body>
          </b-card-group>
        </b-card>
      </b-col>
    </b-row>

    <!-- PRODUCT DETAILS -->
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-body class="p-0">
            <MyTable
              :rows="[details]"
              :columns="columns"
              :is-loading="isLoading"
              :title="$t('product_details')"
              :search-enabled="false"
              :pagination-enabled="false"
            >
              <template v-slot="{props}">
                <span v-if="props.column.field === 'category'">
                  {{ props.row.category.text }}
                </span>

                <span v-else-if="props.column.field === 'packaging'">
                  {{ props.row.packaging.text }}
                </span>

                <span v-else-if="props.column.field === 'size'">
                  {{ props.row.size }} {{ props.row.unit }}
                </span>

                <span v-else-if="props.column.field === 'expiry_date'">
                  {{ $helpers.formatDate(props.row.po_expiry_date) }}
                </span>

                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </MyTable>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="3"
        class="ml-auto"
      >
        <b-card>
          <b-card-body class="p-0">
            <b-row>
              <b-col
                cols="6"
                class="pr-0"
              >
                <h5
                  class="text-light bg-primary py-1 px-1 rounded-left"
                >
                  <strong>{{ $t('total_earn') }}</strong>
                </h5>
              </b-col>
              <b-col
                cols="6"
                class="pl-0"
              >
                <h5
                  class="py-1 px-1 border rounded-right"
                >
                  <strong>{{ $helpers.formatTotal(totalEarn) }}</strong>
                </h5>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="12">
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-center"
          >
            <b-button
              v-if="isExisting"
              variant="success"
              class="mx-2"
              @click="handleAddToCart"
            >
              {{ $t('item_is_in_cart') }}
            </b-button>
            <b-button
              v-if="!isExisting"
              variant="primary"
              class="mx-2"
              @click="handleAddToCart"
            >
              {{ $t('add_to_cart') }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyTable from '@/components/MyTable.vue'

export default {
  name: 'PurchaseOrderDetails',
  components: {
    MyTable,
  },
  data() {
    return {
      columns: [
        {
          field: 'category',
          label: this.$t('category'),
          sortable: false,
        },
        {
          field: 'quantity',
          label: this.$t('quantity'),
          sortable: false,
        },
        {
          field: 'packaging',
          label: this.$t('packaging'),
          sortable: false,
        },
        {
          field: 'size',
          label: this.$t('size'),
          sortable: false,
        },
        {
          field: 'sku',
          label: 'SKU',
          sortable: false,
        },
        {
          field: 'temperature',
          label: this.$t('temperature'),
          sortable: false,
        },
        {
          field: 'expiry_date',
          label: this.$t('expiry_date'),
          sortable: false,
        },
        {
          field: 'remarks',
          label: this.$t('remarks'),
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      details: 'orders/orderDetails',
      isLoading: 'orders/checkLoadingState',
      cart: 'getCartItems',
    }),
    totalEarn() {
      return (Math.round((+this.details.smarttruck_cut + (this.details.pickup_charge ? +this.details.pickup_charge : 0)) * 100) / 100).toFixed(2)
    },
    isExisting() {
      return this.cart.find(item => item.id === this.details.id)
    },
  },
  methods: {
    async handleAddToCart() {
      const checkItem = await this.cart.find(item => item.id === this.details.id)
      if (checkItem) {
        return this.$swal({
          title: this.$t('item_is_in_cart'),
          icon: 'warning',
          confirmButtonText: 'OK',
          timer: 4000,
          timerProgressBar: true,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }

      const order = this.details
      await this.$store.dispatch('addToCart', { order })
      return this.$swal({
        title: this.$t('item_is_added_in_cart'),
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>
