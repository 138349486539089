<template>
  <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header>
      <b-card-title class="text-uppercase">
        <h1>
          <strong>
            <FeatherIcon
              icon="BoxIcon"
              size="30"
            /> {{ $t('order_summary') }}
          </strong>
        </h1>
      </b-card-title>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
        >
          <b-list-group>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('order_id') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.reference_id }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('status') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span
                  :class="`text-${order.status.color_name} d-block font-weight-bolder`"
                >
                  {{ order.status.text }}
                </span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('total_earn') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ $helpers.formatTotal(+order.smarttruck_cut + +order.pickup_charge) }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('order_place_date') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ $helpers.formatDateTime(order.date_added) }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('delivery_date') }}:</strong>
              </h5>
              <h5>
                <span class="d-block font-weight-normal">{{ order.delivery_date }}</span>
              </h5>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'OrderDetails',
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
}
</script>
