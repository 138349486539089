<template>
  <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header>
      <b-card-title class="text-uppercase">
        <h1>
          <strong>
            <FeatherIcon
              icon="TruckIcon"
              size="30"
            />
            Smarttruck
          </strong>
        </h1>
      </b-card-title>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <b-row v-if="order.vehicle">
        <b-col cols="12">
          <b-list-group flush>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('vehicle_type') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.vehicle.type }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('plate_number') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal text-uppercase">{{ order.vehicle.plate }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('drivers_name') }}:</strong>
              </h5>
              <h5 class="pb-1">
                <span class="d-block font-weight-normal">{{ order.driver ? order.driver.full_name : 'No driver found.' }}</span>
              </h5>
            </b-list-group-item>
            <b-list-group-item class="border-0 py-0">
              <h5>
                <strong>{{ $t('companys_name') }}:</strong>
              </h5>
              <h5>
                <span class="d-block font-weight-normal">{{ order.user.name }}</span>
              </h5>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <b-list-group flush>
            <b-list-group-item class="border-0">
              <h5>
                {{ $t('driver_not_available') }}
              </h5>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'SmartTruck',
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    order: {
      required: true,
      type: [Object, Array],
    },
  },
}
</script>

<style>

</style>
