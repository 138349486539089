<template>
  <b-modal
    id="modal-add-vehicle"
    ref="modal-add-vehicle"
    size="lg"
    :centered="!checkModalPage"
    :title="$t('add_vehicle')"
    no-close-on-backdrop
    hide-footer
    scrollable
    @show="resetModal"
  >
    <b-container
      v-if="!checkModalPage"
    >
      <b-row align-v="center">
        <b-col
          cols="12"
          lg="6"
          class="mx-auto pb-2"
        >
          <!-- basic -->
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <img
                v-if="image.length > 0"
                :src="image"
                :alt="$t('vehicle_image')"
                class="w-100 img-thumbnail"
              >
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group
                :label="$t('upload_vehicle_picture')"
                label-for="vehicle_image"
              >
                <input
                  id="vehicle_image"
                  ref="imageUploadInput"
                  type="file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  class="form-control"
                  @change="onSelectFile"
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              lg="6"
              class="mx-auto"
            >
              <b-button
                v-if="!isUploading"
                variant="danger"
                size="sm"
                class="w-100"
                @click="getUploadedImageDetails"
              ><b-icon-upload /> {{ $t('upload') }}</b-button>
              <b-button
                v-else
                variant="primary"
                class="w-100 btn-icon"
              ><b-spinner
                small
              /></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-container
      v-else
      class="pt-2"
    >
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <div class="card position-relative mx-auto">
                <div class="position-absolute mt-1 ml-1">
                  <input
                    ref="newImageUpload"
                    type="file"
                    accept="image/*"
                    style="display: none"
                    @change="onSelectFile"
                  >
                  <b-button
                    v-if="!isUploading"
                    class="btn-icon rounded-circle"
                    variant="danger"
                    @click="onFilePick"
                  ><b-icon-pencil-square /></b-button>
                  <b-button
                    v-else
                    variant="primary"
                    class="w-100 btn-icon"
                  ><b-spinner
                    small
                  /></b-button>
                </div>
                <b-img
                  :src="image"
                  center
                  thumbnail
                  fluid
                  :alt="$t('vehicle_image')"
                />
              </div>
            </b-col>
            <b-col
              md="12"
              class="text-center"
            >
              <h2>
                <strong>{{ vehicleData.plate ? vehicleData.plate.toUpperCase() : '' }}</strong>
              </h2>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="6"
          class="px-4"
        >
          <validation-observer
            ref="vehicleDetailsRules"
            tag="form"
          >
            <b-form-group
              :label="$t('vehicle_model')"
              label-for="model-of-vehicle"
            >
              <validation-provider
                #default="{ errors }"
                name="model vehicle"
                rules="required"
              >
                <b-form-input
                  id="model-of-vehicle"
                  v-model="vehicleData.model"
                  type="text"
                  :placeholder="$t('enter_vehicle_model')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('vehicle_make')"
              label-for="make-of-vehicle"
            >
              <validation-provider
                #default="{ errors }"
                name="maker vehicle"
                rules="required"
              >
                <b-form-input
                  id="make-of-vehicle"
                  v-model="vehicleData.make"
                  type="text"
                  :placeholder="$t('enter_vehicle_make')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('vehicle_color')"
              label-for="color-of-vehicle"
            >
              <validation-provider
                #default="{ errors }"
                name="color vehicle"
                rules="required"
              >
                <b-form-input
                  id="color-of-vehicle"
                  v-model="vehicleData.color"
                  type="text"
                  :placeholder="$t('enter_vehicle_color')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <b-container class="p-2">
            <b-form-checkbox
              id="checked"
              v-model="checked"
              type="checkbox"
              true-value="yes"
              false-value=""
            >
              <p>
                {{ $t('accuracy_of_information') }}
              </p>
              <div style="color: green">
                {{ $t('note') }} {{ $t('modify_vehicle_after_saving') }}
              </div>
            </b-form-checkbox>
          </b-container>
        </b-col>

      </b-row>
      <b-row class="px-5 mx-5">
        <b-col
          md="12"
          class="w-50"
        >
          <b-row>
            <b-col>
              <b-button
                variant="danger"
                :disabled="submitted"
                class="w-100 mb-2"
                @click="backButton"
              ><b-icon-backspace /> {{ $t('back') }}</b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="!submitted"
                :disabled="Boolean(!checked)"
                variant="success"
                class="w-100"
                @click="handleSaveVehicle"
              ><b-icon-hand-thumbs-up /> {{ $t('submit') }}</b-button>
              <b-button
                v-if="submitted"
                variant="primary"
                class="w-100 btn-icon"
              ><b-spinner
                small
              /></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
/* eslint-disable-next-line */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSpinner,
  BIconBackspace,
  BIconHandThumbsUp,
  BIconPencilSquare,
  BIconUpload,
} from 'bootstrap-vue'

export default {
  name: 'AddVehicleModal',
  components: {
    // Icons
    BSpinner,
    BIconBackspace,
    BIconHandThumbsUp,
    BIconPencilSquare,
    BIconUpload,

    // Package
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    parentImg: {
      required: true,
      type: Array,
    },
    parentSubmitted: Boolean,
  },
  data() {
    return {
      image: this.parentImg,
      submitted: this.parentSubmitted,
      checked: false,
      isHovered: false,
      vehicleFile: '',
      vehicleData: {
        model: '',
        plate: '',
        color: '',
      },
      checkModalPage: false,
      isUploading: false,
    }
  },
  computed: {
    checkIfHasUpload() {
      return Boolean(this.newUpload)
    },
  },
  methods: {
    async onSelectFile(e) {
      const file = e.target.files[0]
      if (file) {
        this.vehicleFile = file
        if (this.checkModalPage) {
          await this.getUploadedImageDetails()
        }
        const reader = new FileReader()
        reader.onload = evt => {
          this.image = evt.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    async getUploadedImageDetails() {
      this.isUploading = true
      const formData = new FormData()
      formData.append('vehicle_image', this.vehicleFile)
      this.$http.post('/search-vehicle', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          if (response.status === 200) {
            this.isUploading = false
            this.vehicleData = { ...this.vehicleData, ...response.data.data }
            this.checkModalPage = true
          }
        })
        .catch(() => {
          this.isUploading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('invalid_vehicle_image'),
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async handleSaveVehicle() {
      this.$refs.vehicleDetailsRules.validate().then(async success => {
        if (success) {
          this.submitted = true
          const formData = new FormData()
          formData.append('vehicle_image', this.vehicleFile)
          Object.keys(this.vehicleData).forEach(key => {
            formData.append(key, this.vehicleData[key])
          })
          this.$http.post('/vehicles', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(response => {
              if (response.status === 200) {
                this.submitted = false
                this.$refs['modal-add-vehicle'].hide()
                this.$emit('get-vehicles')
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('vehicle_created'),
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                })
              }
              this.submitted = false
            })
            .catch(err => {
              console.log(err)
              this.submitted = false
            })
        }
      })
    },
    backButton() {
      this.image = []
      this.vehicleFile = []
      this.vehicleData = []
      this.checkModalPage = false
    },
    onFilePick() {
      this.$refs.newImageUpload.click()
    },
    /* eslint-disable */
    resetModal() {
      this.vehicleFile = '',
      this.vehicleData = {
        model: '',
        plate: '',
        color: '',
      }
    /* eslint-enable */
      this.checkModalPage = false
      this.image = this.parentImg
    },
  },
}
</script>
