<template>
  <b-col class="mb-2">
    <b-row>
      <b-col cols="6" lg="4" class="ml-auto">
        <b-row>
          <b-col lg="6" class="ml-auto">
            <b-button
              variant="danger"
              v-b-modal.modal-add-vehicle
              class="w-100"
            >{{ buttonTitle }}</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: 'MyVehicleAddButton',
  props: {
    buttonTitle: String
  }
}
</script>